import React from "react";
import Pricing from "./components/Pricing";
import Features from "./components/Features";
import Faqs from "./components/Faqs";
import Testimonials from "./components/Testimonials";

function Home({
  amountToPay,
  currency,
  halfPrice,
  professionalPrice,
  setSell,
  page,
  setAccepted,
  setDProgram,
}) {
  return (
    <>
      <div data-spy="scroll" data-offset="0" id="content">
        <div id="useTemplate">
          <div className="bg-shape bg-dark">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <div className="pt-lg-18 pb-lg-14 pt-14 pb-12  ">
                    <h1
                      className="h2 text-white"
                      style={{
                        "--coach-text-opacity": 1,
                        textAlign: "center",
                      }}
                    >
                      Blackforce Subscription Plan
                    </h1>
                    <div
                      className="text-danger h3"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      **
                      <span
                        style={{
                          letterSpacing: "-0.48px",
                        }}
                      >
                        This Subscription window is for <br />
                        Blackforce Associate Video and Certification Prep Access
                        Only
                      </span>
                      **
                    </div>
                    <center className="col-12 mx-auto text-white h4"></center>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-16 mt-lg-n12  mt-n6">
            <div className="container">
              <Pricing
                page={page}
                amountToPay={amountToPay}
                currency={currency}
                halfPrice={halfPrice}
                professionalPrice={professionalPrice}
                setSell={setSell}
                setAccepted={setAccepted}
                setDProgram={setDProgram}
              />
              <Features />
              <Faqs />
              <Testimonials />
              <center>
                <a href="#pricing" className="btn btn-primary">
                  Proceed with registration
                </a>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
