import React from "react";

function CourseTile({ course }) {
  return (
    <li className="d-flex justify-content-between align-items-center mb-2">
      <span>
        {course.title}
        {course.isOffered && !course.live && (
          <span className="text-danger">*</span>
        )}
      </span>
      {course.isOffered ? (
        <span className="text-success font-12">
          <span className="fas fa-check-circle"></span>
        </span>
      ) : (
        <span className="text-danger font-12">
          <span className="fas fa-times-circle"></span>
        </span>
      )}
    </li>
  );
}

export default CourseTile;
