import axios from "axios";

export const PAGE_URL = process.env.REACT_APP_API_URL + "/page";

export function verifyCoupon(data, businessid) {
  return axios.post(
    process.env.REACT_APP_AFFILIATE_URL + "/promotion-code/verify",
    data,
    { headers: { businessid } }
  );
}

export function checkCoupon(code, businessid) {
  return axios.get(
    process.env.REACT_APP_AFFILIATE_URL + "/promotion-code/check/" + code,
    { headers: { businessid } }
  );
}

export function findTaxrates(queryParams = {}) {
  return axios.get(
    `${
      process.env.REACT_APP_TEACHNOW_APP_URL + "/taxrates"
    }/?queryParams=${JSON.stringify(queryParams)}`
  );
}

export function createLead(page) {
  return axios.post(PAGE_URL + "/createLead", page);
}

export function deletePageData(pageId, settings) {
  return axios.post(`${PAGE_URL}/page_data/${pageId}`, { settings });
}

export function updatePageData(page) {
  return axios.put(`${PAGE_URL}/page_data`, page);
}
