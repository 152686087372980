import React from "react";

export default function Testimonials() {
  return (
    <>
      <div className="left-slant-shape py-12 pt-lg-18 pb-lg-16 right-slant-shape">
        <div className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6 col-md-12 col-12">
              <div className="mb-8 text-center">
                <h2 className="h1 mb-3">
                  What our students are saying about the Instructors
                </h2>
                <p className="px-lg-8 px-0 lead">
                  Our training programs strive to develop your skills, empower
                  you and transform your career.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="card mb-lg-0 mb-4 border-0">
                <div className="card-body p-6">
                  <h5 className="mb-4  font-italic">
                    "He is who you call a teacher"
                  </h5>
                  <p className="mb-4">
                    Babatope's dedication to making sure people excel in
                    Salesforce needs to be studied in school. Then his
                    generosity is one to emulate. How he is willing to give and
                    give is certainly something I appreciate about Babatope. He
                    looks and sounds very strict, of which he is actually, but
                    beneath that hardness is a great soul. I wish he'd soften up
                    a bit and laugh more often.
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/testimonial6.jpeg"
                      alt=""
                      className="rounded-circle mr-3 icon-lg"
                    />
                    <div>
                      <h5 className="mb-0 ">Ify Okongwu</h5>
                      <span className="font-12">
                        Salesforce Business Analyst / Administrator
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="card mb-lg-0 mb-4 border-0">
                <div className="card-body p-6">
                  <h5 className="mb-4  font-italic">
                    “He sparked my interest in Salesforce”
                  </h5>
                  <p className="mb-4">
                    I had no experience in salesforce; but the simplicity and
                    passion with which the lessons were delivered sparked my
                    interest in Salesforce and made everyone in my cohort look
                    forward to the classes. I recommend this salesforce training
                    class for everyone who is looking to learn.
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/testimonial2.jpeg"
                      alt=""
                      className="rounded-circle mr-3 icon-lg"
                    />
                    <div>
                      <h5 className="mb-0">Emilia Anochirionye</h5>
                      <span className="font-12">
                        Salesforce Developer &amp; Administrator
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="card mb-lg-0 mb-4 border-0">
                <div className="card-body p-6">
                  <h5 className="mb-4  font-italic">
                    "I'm Living My Purpose, and I Couldn't Be Happier"
                  </h5>
                  <p className="mb-4">
                    The training was an eye opener. I haven't really heard much
                    about Sales prior to the cohort but even at that Babatope
                    made everything sounds so easy, even the complicated ones.
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/testimonial3.jpeg"
                      alt=""
                      className="rounded-circle mr-3 icon-lg"
                    />
                    <div>
                      <h5 className="mb-0 ">Anthony Olasinde</h5>
                      <span className="font-12">Salesforce Developer</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="card mb-lg-0 mb-4 border-0">
                <div className="card-body p-6">
                  <h5 className="mb-4  font-italic">
                    “The training was intense and well done.”
                  </h5>
                  <p className="mb-4">
                    The training was intense and well done. It included
                    lectures, assignments and a lot of practice. If you would
                    like to be part of Salesforce ecosystem, I would recommend
                    this training..
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/testimonial5.jpeg"
                      alt=""
                      className="rounded-circle mr-3 icon-lg"
                    />
                    <div>
                      <h5 className="mb-0">Sagal Mursal</h5>
                      <span className="font-12">
                        Senior Salesforce Administrator
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="card mb-lg-0 mb-4 border-0">
                <div className="card-body p-6">
                  <h5 className="mb-4  font-italic">
                    "Fast paced and handsOn."
                  </h5>
                  <p className="mb-4">
                    Fast paced and handsOn. One of the projects we had to work
                    on during the training was actually the trigger for my
                    first/ current Salesforce role. Just a brief description of
                    the project caused the most senior interviewer to take
                    interest in me/ my resume . Thank you Babatope
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/testimonial1.jpeg"
                      alt=""
                      className="rounded-circle mr-3 icon-lg"
                    />
                    <div>
                      <h5 className="mb-0 ">Adetola Ajao</h5>
                      <span className="font-12">Salesforce Administrator</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="card mb-lg-0 mb-4 border-0">
                <div className="card-body p-6">
                  <h5 className="mb-4  font-italic">
                    “He was very thorough, patient and practical in his
                    teachings”
                  </h5>
                  <p className="mb-4">
                    Babatope was very thorough, patient and practical in his
                    teachings. He made SF interesting to explore. What I loved
                    most is how we treated case studies and projects in class
                    giving you an expert judgement on how to approach business
                    problems.
                  </p>
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/avatar-1.png"
                      alt=""
                      className="rounded-circle mr-3 icon-lg"
                    />
                    <div>
                      <h5 className="mb-0">Titilope Abiola-Taiwo</h5>
                      <span className="font-12">Salesforce Administrator</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
