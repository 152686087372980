import React from "react";

export default function Features() {
  return (
    <>
      <div className="row mt-15" id="features">
        <div className="offset-lg-3 col-lg-6 col-md-12 col-12 text-center mb-10">
          <h2>Subscription plan features.</h2>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="mb-9">
            <svg
              width={40}
              height={40}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.6667 5H8.33333C6.49238 5 5 6.49238 5 8.33333V31.6667C5 33.5076 6.49238 35 8.33333 35H31.6667C33.5076 35 35 33.5076 35 31.6667V8.33333C35 6.49238 33.5076 5 31.6667 5Z"
                fill="#FFEAE6"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 15H35"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 35V15"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="mt-5">Salesforce Training Classes</h4>
            <p className="mb-0">
              We provide some free Salesforce training to all our registered
              members whether they have active subscriptions or not. This
              training will enable trainees to apply for Salesforce jobs or
              switch to Salesforce designated roles within their organization.
              <br />
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="mb-9">
            <svg
              width={40}
              height={40}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8346 3.33301H33.3346V36.6663H10.8346C9.72957 36.6663 8.66976 36.2274 7.88836 35.446C7.10696 34.6646 6.66797 33.6047 6.66797 32.4997V7.49967C6.66797 6.39461 7.10696 5.3348 7.88836 4.5534C8.66976 3.77199 9.72957 3.33301 10.8346 3.33301Z"
                fill="#FFEAE6"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.66797 32.4997C6.66797 31.3946 7.10696 30.3348 7.88836 29.5534C8.66976 28.772 9.72957 28.333 10.8346 28.333H33.3346"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="mt-5">Salesforce Certification Prep</h4>
            <p className="mb-0">
              After training, a special class for members will be organized for
              those interested in taking relevant Salesforce Certification
              exams. Not all certification prep will be provided. Only
              subscribed standard or professional members will be eligible for
              this Class.
              <br />
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="mb-9">
            <svg
              width={40}
              height={40}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9987 5L25.1487 15.4333L36.6654 17.1167L28.332 25.2333L30.2987 36.7L19.9987 31.2833L9.6987 36.7L11.6654 25.2333L3.33203 17.1167L14.8487 15.4333L19.9987 5Z"
                fill="#FFEAE6"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="mt-5">Mock Certification Test</h4>
            <p className="mb-0">
              Our online test-taking platform offers a mock certification test
              to all Blackforce registered members. Anyone can take this test to
              assess their current knowledge of Salesforce before taking an
              exam. The platform is available during the training period.
              <br />
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="mb-9">
            <svg
              width={40}
              height={40}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.6667 6.66699H8.33333C6.49238 6.66699 5 8.15938 5 10.0003V33.3337C5 35.1746 6.49238 36.667 8.33333 36.667H31.6667C33.5076 36.667 35 35.1746 35 33.3337V10.0003C35 8.15938 33.5076 6.66699 31.6667 6.66699Z"
                fill="#FFEAE6"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M26.668 3.33301V9.99967"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.332 3.33301V9.99967"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 16.667H35"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="mt-5">Resume Template & Review</h4>
            <p className="mb-0">
              A Salesforce resume template will be provided for all members
              actively looking for a Salesforce job. For those with an active
              professional subscription, a member of the team will review your
              Salesforce resume and provide relevant guidance. A booking will be
              required for this session if a One - on - One session is required.
              <br />
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="mb-9">
            <svg
              width={40}
              height={40}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.6667 6.66699H8.33333C6.49238 6.66699 5 8.15938 5 10.0003V33.3337C5 35.1746 6.49238 36.667 8.33333 36.667H31.6667C33.5076 36.667 35 35.1746 35 33.3337V10.0003C35 8.15938 33.5076 6.66699 31.6667 6.66699Z"
                fill="#FFEAE6"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M26.668 3.33301V9.99967"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.332 3.33301V9.99967"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 16.667H35"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="mt-5">Salesforce Interview Prep</h4>
            <p className="mb-0">
              All our subscribed members (standard & professional) will have the
              opportunity to attend group salesforce interview preparation
              providing them with typical Salesforce Interview questions and
              directions on how to answer them. This will also be used as a
              forum to provide insights into the ecosystem and future career
              paths.
              <br />
            </p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
          <div className="mb-9">
            <svg
              width={40}
              height={40}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9987 36.6663C29.2034 36.6663 36.6654 29.2044 36.6654 19.9997C36.6654 10.7949 29.2034 3.33301 19.9987 3.33301C10.794 3.33301 3.33203 10.7949 3.33203 19.9997C3.33203 29.2044 10.794 36.6663 19.9987 36.6663Z"
                fill="#FFEAE6"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.1484 15C15.5403 13.8861 16.3137 12.9468 17.3317 12.3485C18.3497 11.7502 19.5466 11.5315 20.7104 11.7312C21.8742 11.9308 22.9298 12.5358 23.6902 13.4392C24.4507 14.3425 24.8669 15.4858 24.8651 16.6666C24.8651 20 19.8651 21.6666 19.8651 21.6666"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 28.333H20.0167"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="mt-5">Individual & Group Project Review</h4>
            <p>
              Individual & Group projects ensure members actively seeking a
              Salesforce job can build on their skills by working on a project.
              However, only our active professional subscribers will receive
              feedback on their individual & group projects. Active professional
              members can request One-on-One feedback via our mentor's
              appointment booking platform.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="mb-9">
            <svg
              width={40}
              height={40}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9987 36.6663C29.2034 36.6663 36.6654 29.2044 36.6654 19.9997C36.6654 10.7949 29.2034 3.33301 19.9987 3.33301C10.794 3.33301 3.33203 10.7949 3.33203 19.9997C3.33203 29.2044 10.794 36.6663 19.9987 36.6663Z"
                fill="#FFEAE6"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.1484 15C15.5403 13.8861 16.3137 12.9468 17.3317 12.3485C18.3497 11.7502 19.5466 11.5315 20.7104 11.7312C21.8742 11.9308 22.9298 12.5358 23.6902 13.4392C24.4507 14.3425 24.8669 15.4858 24.8651 16.6666C24.8651 20 19.8651 21.6666 19.8651 21.6666"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 28.333H20.0167"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="mt-5">Recorded Training Videos</h4>
            <p>
              Recorded Training videos are made available within 12 hours after
              the conclusion of a training session. Access to recorded training
              videos are done in two ways:
            </p>
            <p>
              The first method - all members that attended a training session
              would access the recorded video through their Single Log-In
              Account. Members who do not attend a training session will not
              have access to a recorded video.
            </p>
            <p>
              The second method- in addition to the above, all subscribed
              members to a program, whether they attended a training session or
              not, can access recorded videos through My Learning App on their
              Single Log-In Account.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="mb-9">
            <svg
              width={40}
              height={40}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9987 36.6663C29.2034 36.6663 36.6654 29.2044 36.6654 19.9997C36.6654 10.7949 29.2034 3.33301 19.9987 3.33301C10.794 3.33301 3.33203 10.7949 3.33203 19.9997C3.33203 29.2044 10.794 36.6663 19.9987 36.6663Z"
                fill="#FFEAE6"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.1484 15C15.5403 13.8861 16.3137 12.9468 17.3317 12.3485C18.3497 11.7502 19.5466 11.5315 20.7104 11.7312C21.8742 11.9308 22.9298 12.5358 23.6902 13.4392C24.4507 14.3425 24.8669 15.4858 24.8651 16.6666C24.8651 20 19.8651 21.6666 19.8651 21.6666"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 28.333H20.0167"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="mt-5">Salesforce Mentorship</h4>
            <p>
              Through our platform, trainees can book an appointment with a
              Blackforce Certified Salesforce Consultant for some invaluable
              mentoring as they embark on their new Salesforce career.
            </p>
            <p>
              To accomplish this, we have created two types of mentorship
              programs - the Group Mentorship and the One-on-One Mentorship.
            </p>
            <p>
              The Group mentorship plan is offered via our community Telegram
              group. Any registered member will have the opportunity to ask any
              Salesforce related questions or ask about Salesforce work-related
              tasks. They will receive a response from a senior member of the
              Blackforce community.
            </p>
            <p>
              The One-on-One Mentorship guarantees private mentorship with a
              Salesforce certified member of the community. This is only
              reserved for Professional subscribers. Subscribed members can book
              a session with a Mentor. You have a maximum of 5 hours with a
              Mentor throughout the duration of your subscription.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="mb-9">
            <svg
              width={40}
              height={40}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9987 36.6663C29.2034 36.6663 36.6654 29.2044 36.6654 19.9997C36.6654 10.7949 29.2034 3.33301 19.9987 3.33301C10.794 3.33301 3.33203 10.7949 3.33203 19.9997C3.33203 29.2044 10.794 36.6663 19.9987 36.6663Z"
                fill="#FFEAE6"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.1484 15C15.5403 13.8861 16.3137 12.9468 17.3317 12.3485C18.3497 11.7502 19.5466 11.5315 20.7104 11.7312C21.8742 11.9308 22.9298 12.5358 23.6902 13.4392C24.4507 14.3425 24.8669 15.4858 24.8651 16.6666C24.8651 20 19.8651 21.6666 19.8651 21.6666"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 28.333H20.0167"
                stroke="#FF5938"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="mt-5">Unpaid Internship</h4>
            <p>
              We will provide unpaid internship for members subscribed to
              Salesforce Professional and who are actively seeking to acquire
              Salesforce experience to boost their Resume. The internship is
              provided through our associate company, Manifest & Company Inc.
              The internship will be based on a real Salesforce project and
              team. For instance, you will work as part of a team consisting of
              a Project Manager, Scrum Master, Business Analysts, Quality
              Assurance, Salesforce Developers and Salesforce Administrators.
              Senior Members of the community will lead and support you during
              your internship.
            </p>
            <p>
              We will provide internship reference letters and/or
              recommendations for all professional subscribed members who have
              gone through our intensive training, internship and individual and
              group projects. The reference letter will focus on Salesforce
              technical skills and experience. You must submit all your projects
              before the deadline to qualify for a reference letter.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
