import React from "react";

export default function Faqs() {
  return (
    <>
      {" "}
      <div className="row mt-14" id="faqs">
        <div className="offset-lg-3 col-lg-6 col-md-12 col-12 text-center mb-12">
          <h2>Frequently Asked Questions</h2>
          <p className="mb-0">
            Let us help answer the most common questions you might have.
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-6">
          <h4>Do you provide job opportunities after training?</h4>
          <p className="mb-0">
            No, we do not provide jobs after training. We do, however, provide
            referrals to our partners. Please note - you must have a Salesforce
            certification before we can refer you.
            <br />
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-6">
          <h4>Do you get to interact with other students?</h4>
          <p className="mb-0">
            Yes. We have a vibrant Blackforce Community on Telegram for our
            members. It is a community where our members interact with each
            other, share job-related information and tips, post-Salesforce
            resources, post questions and answers and make general enquiries.
            <br />
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-6">
          <h4>Why should I pay for the training program?</h4>
          <p className="mb-0">
            Our training programs are guaranteed to develop your skills, empower
            you and transform your career. Additionally, you may be provided
            with an opportunity to join our implementation team. This affords
            you the opportunity to work on paid Salesforce projects (on a
            part-time basis) and gain valuable experience while still
            maintaining your full-time job.
            <br />
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-6">
          <h4>Are internships guaranteed?</h4>
          <p className="mb-0">
            No. Internships are not guaranteed. They are only available for
            subscribed members who have actively participated in and fulfilled
            all the training requirements. This would include, working on
            individual projects and submitting by the specified due date,
            actively participating in the training sessions, becoming Salesforce
            certified amongst other things.
            <br />
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-6">
          <h4>Is there access to recorded videos after the training?</h4>
          <p className="mb-0">
            Subscribed members will have access to recorded videos to programs
            they are subscribed to on My Learning App through their Single Login
            account. Non-subscribed members can only access recorded videos if
            they attend the training sessions and further access will be taken
            off after the training.
            <br />
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-6">
          <h4>When will the next training begin?</h4>
          <p className="mb-0">
            The next training program will be communicated by email, our
            Telegram group and LinkedIn page. Please ensure that you are
            registered and subscribed to our mailing list, join the Blackforce
            community on Telegram and follow our LinkedIn page.
            <br />
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-6">
          <h4>Are Payment plans available?</h4>
          <p className="mb-0">
            We do not encourage payment plans, however, Professional members can
            make payments in 2 instalments in this manner: 1st instalment to be
            made before commencement of classes and the 2nd instalment to be
            made on or before the end of the second month following the
            commencement of classes.
            <br />
            Note: You will not be allowed to CONTINUE in any training session if
            full payment for that training is not received on or before the end
            of the second month following the commencement of classes.
            <br />
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mb-6">
          <h4>Is there a refund after payment?</h4>
          <p className="mb-0">
            Unfortunately, we do not offer refunds at this time.
            <br />
          </p>
        </div>
      </div>
    </>
  );
}
