import React from "react";
import "./theme.min.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Country() {
  const page = useLocation();
  let cLocation = page.pathname.substring(1).split("/")[0];

  const { search } = useLocation();
  return (
    <>
      <div className="m-5">
        <div className="docs-example">
          <div className="tab-content" id="pills-tabContent-pricingThird">
            <div
              className="tab-pane tab-example-design fade show active"
              id="pills-pricingThird-design"
              role="tabpanel"
              aria-labelledby="pills-pricingThird-design-tab"
            >
              <section className="py-lg-10 py-6 bg-light">
                <div className="container">
                  {/*row*/}
                  <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-md-12 col-12">
                      <div className="text-center mb-lg-9 mb-5">
                        {/*content*/}
                        <h2 className="fw-bold mb-3">
                          Welcome to BlackForce Salespage
                        </h2>
                        <p className="mb-0">
                          The Blackforce Platform is a Canadian enterprise with
                          a global reach. To proceed to our sales page, kindly
                          choose your current country of residence. This will
                          help us tailor your currency preferences and assess
                          any relevant tax implications.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
                      <div className="row">
                        <div className="col-xl-6 col-md-12 col-lg-6 col-12">
                          {/*card*/}
                          <div className="card mb-5 mb-lg-0">
                            {/*card-body*/}
                            <div className="card-body p-5">
                              {/*content*/}
                              <center className="text-uppercase text-dark">
                                <b>CANADA (CAD + TAX)</b>
                                <div className="my-3">
                                  <img
                                    src={"../assets/images/canada.png"}
                                    alt=""
                                    width="50%"
                                  />
                                </div>
                              </center>
                              {/*list*/}
                              <center>
                                <div>
                                  Payment will be made in CAD. All transactions
                                  will require a tax. Same education and
                                  benefits.
                                </div>
                                {/*button*/}
                                <div className="mt-5 d-grid">
                                  <Link
                                    to={`/${cLocation}/canada`}
                                    className="btn btn-primary"
                                  >
                                    Continue as a Canadian Resident
                                  </Link>
                                </div>
                              </center>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-md-12 col-lg-6 col-12">
                          {/*card*/}
                          <div className="card">
                            {/*card body*/}
                            <div className="card-body p-5">
                              {/*content*/}
                              <center className="text-uppercase text-dark">
                                <b>INTERNATIONAL (USD)</b>
                                <div className="my-3">
                                  <img
                                    src={"../assets/images/intl.png"}
                                    alt=""
                                    width="50%"
                                  />
                                </div>
                              </center>
                              {/*list*/}
                              <center>
                                <div>
                                  Payment will be made in USD. Transactions do
                                  not require a tax. Same education and
                                  benefits.
                                </div>
                                {/*button*/}
                                <div className="mt-5 d-grid">
                                  <Link
                                    to={`/${cLocation}/international`}
                                    className="btn btn-secondary"
                                  >
                                    Continue as a Non-Canadian Resident
                                  </Link>
                                </div>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
