import React from "react";
import { Link } from "react-router-dom";

function Navigation({ page }) {
  return (
    <>
      {" "}
      <div className="header fixed-top border-top-3 border-top border-primary">
        {/* navigation start */}
        <div className="container-lg">
          <nav className="navbar navbar-expand-lg navbar-default">
            <Link className="navbar-brand" to="#">
              <img
                src={page?.logo || "../assets/images/logo.svg"}
                alt=""
                style={{ maxHeight: "60px" }}
              />
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbar-default"
              aria-controls="navbar-default"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar mt-0"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-default">
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-default"
                aria-controls="navbar-default"
                aria-expanded="false"
                aria-label="Toggle navigation"
                id="closeNav"
              >
                <i className="fas fa-times"></i>
              </button>
              <ul className="navbar-nav ml-auto mr-lg-3" id="menu-click">
                <li className="nav-item dropdown disabled">
                  <a className="nav-link d-lg-none" href="#?">
                    Menu
                  </a>
                </li>

                <li className="nav-item ">
                  <a className="nav-link" href="https://blackforce.ca">
                    Home
                  </a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link" href="https://blackforce.ca/program">
                    Our Programs
                  </a>
                </li>

                <li className="nav-item ">
                  <a
                    className="nav-link"
                    href="https://blackforce.ca/program"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Our Programs
                  </a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link" href="#pricing">
                    Pricing
                  </a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link" href="#features">
                    Features
                  </a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link" href="#faqs">
                    FAQs
                  </a>
                </li>
                {/* {editButton && (
                      <>
                        <li className="nav-item dropdown ">
                          <div className="header-btn ">
                            <button
                              type="button"
                              // onClick={() => update_page(!editPage)}
                              className="btn btn-primary btn-sm "
                            >
                              {editPage === true ? "Save" : "Edit"} Page
                            </button>
                          </div>
                        </li>
                        <li className="nav-item dropdown ">
                          <div className="header-btn ml-2">
                            <button
                              type="button"
                              data-toggle="modal"
                              data-target="#resetPage"
                              className="btn btn-dark btn-sm "
                              disabled={resetButton}
                            >
                              Reset Page
                            </button>
                          </div>
                        </li>
                      </>
                    )} */}
              </ul>
            </div>
          </nav>
        </div>
        {/* navigation close */}
      </div>
    </>
  );
}

export default Navigation;
