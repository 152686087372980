export const professionalCourses = [
  {
    title: "Salesforce Administrator",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Sales & Service Cloud",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Data Cloud",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Artificial Intelligence",
    live: true,
    isOffered: true,
  },
  {
    title: "Business Analysis",
    live: true,
    isOffered: true,
  },
  {
    title: "Introduction to Data Analysis",
    live: true,
    isOffered: true,
  },
  {
    title: "Cloud DevOps Engineering",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Platform Developer",
    live: true,
    isOffered: true,
  },

  {
    title: "Introduction to DevOps w/ Azure & AWS",
    live: true,
    isOffered: true,
  },

  {
    title: "Salesforce Admin Tutorial Session",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Admin Clinic Session",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Admin Certification Prep",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Resume Template",
    live: true,
    isOffered: true,
  },
  {
    title: "Individual Salesforce Admin Projects",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Capstone Project",
    live: true,
    isOffered: true,
  },
  {
    title: "Dedicated In-Class Projects Review",
    live: true,
    isOffered: true,
  },
  {
    title: "Group Salesforce Interview Prep",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Resume Review",
    live: true,
    isOffered: true,
  },
  {
    title: "Interview Prep & HR Training",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to previous cohort Training Videos",
    live: true,
    isOffered: true,
  },
  {
    title: "Unpaid Internship",
    live: true,
    isOffered: true,
  },
  {
    title: "Eligible to put Internship on Resume",
    live: true,
    isOffered: true,
  },
  {
    title: "Internship Reference Letter",
    live: true,
    isOffered: true,
  },
  {
    title: "BlackForce CPD Eligibility",
    live: true,
    isOffered: true,
  },
  {
    title: "Certified Business Analysis (CBAP)",
    live: false,
    isOffered: true,
  },
  {
    title: "Salesforce Experience Cloud",
    live: false,
    isOffered: true,
  },
  {
    title: "Salesforce App Builder",
    live: false,
    isOffered: true,
  },

  {
    title: "Introduction to Python Programming",
    live: false,
    isOffered: true,
  },
  {
    title: "Introduction to SQL/SOQL",
    live: false,
    isOffered: true,
  },
  {
    title: "Salesforce Advanced Administrator",
    live: false,
    isOffered: true,
  },
  {
    title: "Salesforce CPQ Training",
    live: false,
    isOffered: true,
  },
  {
    title: "Salesforce Field Service Cloud",
    live: false,
    isOffered: true,
  },
  {
    title: "Salesforce Marketing Cloud",
    live: false,
    isOffered: true,
  },
  {
    title: "Salesforce Non For Profit Cloud",
    live: false,
    isOffered: true,
  },
  {
    title: "Data Analysis, Reports & Dashboard",
    live: false,
    isOffered: true,
  },
  {
    title: "Power BI & Tableau",
    live: false,
    isOffered: true,
  },
  {
    title: "Scrum Master & Agile Training",
    live: false,
    isOffered: true,
  },
  {
    title: "HTML, CSS & JavaScript",
    live: false,
    isOffered: true,
  },
  {
    title: "Project Management Training",
    live: false,
    isOffered: true,
  },
  {
    title: "Salesforce Acct Engagement - Pardot",
    live: false,
    isOffered: true,
  },
  {
    title: "Project Management Professional Exams",
    live: false,
    isOffered: true,
  },
  {
    title: "Project Management Delivery",
    live: false,
    isOffered: true,
  },
];

export const standardCourse = [
  {
    title: "Access to all videos from previous COHORT",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to Salesforce Administrator",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to Salesforce Sales & Service Cloud",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to Salesforce Data Cloud Videos",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to  Financial Services Cloud Videos",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to Salesforce Business Analysis Videos",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to Introduction to Data Analysis Videos",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to Cloud DevOps Engineering Videos",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to Salesforce Platform Developer Videos",
    live: true,
    isOffered: true,
  },

  {
    title: "Access to Introduction to DevOps w/ Azure & AWS",
    live: true,
    isOffered: true,
  },
  // {
  //   title: "Access to Salesforce Admin Clinic and Tutorials Sessions Videos",
  //   live: true,
  //   isOffered: true,
  // },
  // {
  //   title: "Access to Entrepreneurship and Business Modelling Videos",
  //   live: true,
  //   isOffered: true,
  // },
  {
    title: "Access to Certified Business Analysis (CBAP) Videos",
    live: true,
    isOffered: true,
  },
  {
    title: "Project Management Professional (PMP) Videos",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to all Videos except COHORT 9",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to Live Sessions Master Classes",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to Live Sessions Lunch & Learn",
    live: true,
    isOffered: true,
  },
];

export const basicCourses = [
  {
    title: "Salesforce Administrator",
    live: true,
    isOffered: true,
  },

  {
    title: "Salesforce Admin Tutorial Session",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Admin Clinic Session",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Admin Certification Prep",
    live: true,
    isOffered: true,
  },
  {
    title: "Individual Salesforce Admin Projects",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Capstone Project",
    live: true,
    isOffered: true,
  },
  {
    title: "Dedicated In-Class Projects Review",
    live: true,
    isOffered: true,
  },
  {
    title: "Access to previous Admin Training Videos",
    live: true,
    isOffered: true,
  },
  {
    title: "Salesforce Sales & Service Cloud",
    live: true,
    isOffered: false,
  },
  {
    title: "Data Cloud & Salesforce AI",
    live: true,
    isOffered: false,
  },
  {
    title: "Business Analysis",
    live: true,
    isOffered: false,
  },
  //   {
  //     title: "Security Operations (Incident Responder)",
  //     live: true,
  //     isOffered: false,
  //   },
  //   {
  //     title: "Governance, Risk and Compliance",
  //     live: false,
  //     isOffered: false,
  //   },
  {
    title: "Introduction to Data Analysis",
    live: true,
    isOffered: false,
  },

  {
    title: "Salesforce Experience Cloud",
    live: true,
    isOffered: false,
  },
  {
    title: "Salesforce App Builder",
    live: true,
    isOffered: false,
  },

  {
    title: "Introduction to Python Programming",
    live: true,
    isOffered: false,
  },
  {
    title: "Cloud DevOps Engineering",
    live: true,
    isOffered: false,
  },
  {
    title: "Introduction to SQL/SOQL",
    live: true,
    isOffered: false,
  },

  {
    title: "Introduction to AWS",
    live: true,
    isOffered: false,
  },
  {
    title: "Introduction to DevOps with Azure",
    live: true,
    isOffered: false,
  },

  {
    title: "Salesforce Resume Template",
    live: true,
    isOffered: false,
  },

  {
    title: "Group Salesforce Interview Prep",
    live: true,
    isOffered: false,
  },
  {
    title: "Salesforce Resume Review",
    live: true,
    isOffered: false,
  },

  {
    title: "Salesforce Advanced Administrator",
    live: false,
    isOffered: false,
  },
  {
    title: "Salesforce CPQ Training",
    live: false,
    isOffered: false,
  },
  {
    title: "Salesforce Field Service Cloud",
    live: false,
    isOffered: false,
  },
  {
    title: "Salesforce Marketing Cloud",
    live: false,
    isOffered: false,
  },
  {
    title: "Salesforce Non For Profit Cloud",
    live: false,
    isOffered: false,
  },
  {
    title: "Data Analysis, Reports & Dashboard",
    live: false,
    isOffered: false,
  },
  {
    title: "Power BI & Tableau",
    live: false,
    isOffered: false,
  },
  {
    title: "Salesforce Acct Engagement - Pardot",
    live: false,
    isOffered: false,
  },
  {
    title: "Scrum Master & Agile Training",
    live: false,
    isOffered: false,
  },
  {
    title: "HTML, CSS & JavaScript",
    live: false,
    isOffered: false,
  },
  {
    title: "Project Management Training",
    live: false,
    isOffered: false,
  },

  {
    title: "Project Management Professional Exams",
    live: false,
    isOffered: false,
  },
  {
    title: "Project Management Delivery",
    live: false,
    isOffered: false,
  },
  {
    title: "Certified Business Analysis (CBAP)",
    live: true,
    isOffered: false,
  },
  {
    title: "Salesforce Platform Developer",
    live: true,
    isOffered: false,
  },
  {
    title: "Interview Prep & HR Training",
    live: true,
    isOffered: false,
  },
  {
    title: "Unpaid Internship",
    live: true,
    isOffered: false,
  },
  {
    title: "Eligible to put Internship on Resume",
    live: true,
    isOffered: false,
  },
  {
    title: "Internship Reference Letter",
    live: true,
    isOffered: false,
  },
  {
    title: "BlackForce CPD Eligibility",
    live: true,
    isOffered: false,
  },
];
