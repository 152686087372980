/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy, useEffect } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import ErrorPage from "./modules/Errors/ErrorPage";
import { shallowEqual, useSelector } from "react-redux";
import Logout from "./modules/Auth/pages/Logout";
import swal from "sweetalert2";
import { page } from "./modules/Associate/cohortPage";
import Pages from "./modules/Associate/Main";

const MyPages = lazy(() => import("./modules/MyPages/myPages"));
// const Pages = lazy(() => import("./modules/Pages/Pages"));
const SubscriptionPage = lazy(() =>
  import("./modules/Subscription/SubscriptionPage")
);
const SpecialPage = lazy(() => import("./modules/SpecialProgram/specialPage"));
const WaitListPage = lazy(() => import("./modules/WaitList/waitListPage"));
const EarlyBirdPage = lazy(() => import("./modules/EarlyBird/cohortPage"));
const CohortPage = lazy(() => import("./modules/Cohort/cohortPage"));
const AssociatePage = lazy(() => import("./modules/Associate/cohortPage"));
const Login = lazy(() => import("./modules/Auth/pages/Login"));

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const params = new URLSearchParams(window.location.search);
  const queries = Object.fromEntries(params.entries());
  const history = useHistory();

  useEffect(() => {
    queries.session_id &&
      swal.fire(
        "Payment Successful",
        "Please, check your email and follow the instructions stated in your email to complete your registration process.",
        "success"
      );
    history.replace({ search: "" });
  }, [queries.session_id, history]);

  return (
    <Switch>
      {!isAuthorized ? (
        <Route
          path="/auth/login/:status?/:token?/:refresh_token?/:user?"
          component={Login}
        />
      ) : (
        <Redirect from="/auth" to="/admin" />
      )}

      <Route path="/logout" component={Logout} />
      <Route path="/error" component={ErrorPage} />

      {/* <Redirect exact from="/" to="/waitlist" /> */}
      <Redirect exact from="/" to="/subscription" />
      <Redirect exact from="/early-bird" to="/cohort" />

      {/* <Redirect exact from="/" to="/waitlist" /> */}
      <Route exact path="/subscription" component={SubscriptionPage} />
      <Route path="/special-programs" component={SpecialPage} />
      <Route
        path="/associate"
        // component={AssociatePage}
        render={(props) => (
          <Pages
            {...props}
            page={page}
            queryParams={new URLSearchParams(window.location.search)}
          />
        )}
      />
      {/* <Route path="/waitlist" component={WaitListPage} /> */}
      <Route path="/cohort" component={CohortPage} />
      {/* <Route path="/early-bird" component={EarlyBirdPage} /> */}

      {/* create a route that redirects the base page / to
       the current period based on an api call. */}

      {!isAuthorized ? (
        <Redirect to="/auth/login" />
      ) : (
        <Route exact path="/admin" component={MyPages} />
      )}

      {/* <Redirect to="/error" /> */}
    </Switch>
  );
}
